import React from "react";
import { useGlobalState } from "../../state/GlobalStateProvider";
const CardSponsor = ({ logo }) => {
  const { apiUrl } = useGlobalState();
  return (
    <div className="brand-slider-item w-full mx-[16px] my-5 flex justify-center items-center select-none">
      <img src={apiUrl +"public/uploads/images/"+ logo} alt="" />
    </div>
  );
};

export default CardSponsor;
