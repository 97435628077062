import React from "react";

//component
import SliderSponsors from "../../components/Sliders/SliderSponsors";

const ClientSection = () => {
  return (
    <section className="bg-slate-100">
      <div className="max-w-[1200px] mx-auto py-[50px] ">
      <h2 className="text-5xl text-primary font-bold text-center">Our Clients</h2>
        <div className="">
          <SliderSponsors />
        </div>
      </div>
    </section>
  );
};

export default ClientSection;
