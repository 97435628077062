import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGlobalState } from "../../state/GlobalStateProvider";

// components
import SectionHeader from "../../components/SectionHeader";
import FormSentMessage from "../../components/Forms/FormSentMessage";
import Newsletter from "../../components/Newsletter";
import CardServiceLink from "../../components/Cards/CardServiceLink";
import InputDatePicker from "../../components/Inputs/InputDatePicker";

const BookService = () => {
  const {
    services: { loading, data, error },
    apiUrl,
  } = useGlobalState();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const filteredItem = data.map((item) => ({
      label: item.title,
      value: item._id,
    }));
    setOptions(filteredItem);
  }, [loading]);
  const [startDate, setStartDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const service = options.find((item) => item.value === data.serviceId);
    data.bookingDate = startDate.toLocaleString().split(", ")[0];
    data.serviceName = service.label;
    try {
      const response = await fetch(apiUrl + `api/v1/orders`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      reset();
      toast.success(result.message);
    } catch (error) {
      reset();
      toast.error(error.message);
    }
  };

  return (
    <section>
      <SectionHeader title="Book Service" />
      <div className="max-w-[1200px] mx-auto lg:md:py-[120px] py-[60px]">
        <div className="grid lg:md:grid-cols-3 grid-cols-1 lg:md:gap-x-5 gap-0 gap-y-5 mx-4">
          <div className="col-span-2">
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 border">
              <div className="mb-5 pt-3 grid lg:md:grid-cols-2 grid-cols-1 gap-x-[30px] gap-y-3">
                <div>
                  <label
                    htmlFor="fullName"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Full Name*
                  </label>
                  <input
                    {...register("fullName", {
                      required: true,
                    })}
                    aria-invalid={errors.phone ? "true" : "false"}
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Enter your full Name"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                  {errors.phone?.type === "required" && (
                    <p role="alert" className="pt-2 text-red-500">
                      Error !! Full Name is required
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Email Address
                  </label>
                  <input
                    {...register("email", {
                      required: false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    })}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="mb-5 pt-3 grid lg:md:grid-cols-2 grid-cols-1 gap-x-[30px] gap-y-3">
                <div>
                  <label
                    htmlFor="phone"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Phone Number*
                  </label>
                  <input
                    {...register("phoneNo", {
                      required: true,
                    })}
                    aria-invalid={errors.phone ? "true" : "false"}
                    type="text"
                    name="phoneNo"
                    id="phoneNo"
                    placeholder="Enter your phone number"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                  {errors.phoneNo?.type === "required" && (
                    <p role="alert" className="pt-2 text-red-500">
                      Error !! Phone number is required
                    </p>
                  )}
                </div>
                <div>
                  <label className="mb-3 block text-base font-semibold text-[#07074D]">
                    Address Details*
                  </label>
                  <div className="-mx-3 flex flex-wrap">
                    <div className="w-full px-3">
                      <div className="">
                        <input
                          {...register("address", {
                            required: true,
                          })}
                          aria-invalid={errors.area ? "true" : "false"}
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Enter Address"
                          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                        {errors.address?.type === "required" && (
                          <p role="alert" className="pt-2 text-red-500">
                            Error !! Address is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5 pt-3 grid lg:md:grid-cols-2 grid-cols-1 gap-x-[30px] gap-y-3">
                <div>
                  <label className="mb-3 block text-base font-semibold text-[#07074D]">
                    Select Service*
                  </label>
                  <div className="-mx-3 flex flex-wrap">
                    <div className="w-full px-3">
                      <div className="">
                        <select
                          {...register("serviceId", {
                            required: true,
                          })}
                          name="serviceId"
                          id="serviceId"
                          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                          // value={value}
                          // onChange={onChange}
                        >
                          <option value="">Select Service</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.serviceId?.type === "required" && (
                          <p role="alert" className="pt-2 text-red-500">
                            Error !! Service is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Select Booking Date
                  </label>
                  <InputDatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
              </div>
              <div>
                <input
                  type="submit"
                  className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none cursor-pointer"
                  value={"Book Service"}
                />
              </div>
            </form>
          </div>
          <div className="">
            <div className="mb-8">
              <CardServiceLink />
            </div>
            <div className="">
              <Newsletter />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookService;
