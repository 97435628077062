import React, { useRef } from "react";
import CountUp, { useCountUp } from "react-countup";
const CardCountUp = ({ icon, title, description, value }) => {
  const countUpRef = useRef(null);
  useCountUp({
    ref: countUpRef,
    start: 0,
    end: value,
    duration: 3,
    enableScrollSpy: true,
    scrollSpyDelay: 0,
  });
  return (
    <div className="text-center">
      <div className="mb-6 flex justify-center">
        <img className="w-14 h-14" src={icon} alt="" />
      </div>
      <h2 className="mb-2">
        <span className=" text-slate-900 text-5xl font-extrabold mb-2">
          <span ref={countUpRef}></span>+
        </span>
      </h2>
      <h2 className="mb-2">
        <span className="font-semibold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-indigo-300 mb-2">
          {title}
        </span>
      </h2>
      <p className="text-sm text-slate-500">{description}</p>
    </div>
  );
};

export default CardCountUp;
