import React, { useEffect, useState } from "react";
import CardCountUp from "../../components/Cards/CardCountUp";

import icon1 from "../../assets/icons/completed-project.svg";
import icon2 from "../../assets/icons/partner.svg";
import icon3 from "../../assets/icons/satisfied-customer.svg";
const CountUpSection = () => {
  return (
    <section className=" bg-slate-50">
      <section className="max-w-[1200px] mx-auto py-[80px] px-[15px] grid gap-12 md:grid-cols-3 md:gap-16">
        <CardCountUp
          icon={icon1}
          title="Completed Projects"
          description="Successfully delivered diverse projects, showcasing expertise, innovation, and client satisfaction in every endeavor undertaken."
          value={140}
        />
        <CardCountUp
          icon={icon3}
          title="Satisfied Customer"
          value={265}
          description="Thriving on customer delight, our service ensures satisfaction through excellence, reliability, and personalized solutions."
        />
        <CardCountUp
          icon={icon2}
          title="Successful Partnerships"
          value={9}
          description="Forging impactful collaborations, we build success through strong partnerships, innovation, and shared achievements with clients."
        />
      </section>
    </section>
  );
};

export default CountUpSection;
