import React from "react";

// components
import Button from "../../components/Button/Button";

//images
import bannerImg from "../../assets/images/section-bg15.jpg";

const BannerSection = () => {
  return (
    <section
      className="py-[120px] relative z-0 overlay-secondary lg:md:bg-center bg-right bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${bannerImg})`,
      }}
    >
      <div className="flex justify-center">
        <div className="p-[10px] z-[2] text-center">
          <h2 className="text-4xl text-white font-semibold mb-8">
            Feel free to communicate with us
          </h2>
          <Button varient={"link"} href="/book-service">
            Book Service
          </Button>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
