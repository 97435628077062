import React from "react";
import { FaCheck } from "react-icons/fa";

// components
import Button from "../../components/Button/Button";

// images
import image from "../../assets/images/modelImage.png";

const HeroFeatureSection = () => {
  return (
    <section className="max-w-[1200px] mx-auto pt-[60px] pb-10 px-4">
      <div className="flex lg:md:flex-row flex-col gap-5 items-center">
        <div className="relative">
          <img src={image} className="lg:md:max-w-[600px]" alt="" />
        </div>
        <div className="lg:w-[40%] w-full">
          <h4 className="rtin-title">
            <span className="font-semibold">Making Your House</span>
            <br />
            <span className="font-light">As Good As New</span>
          </h4>
          <p className="text-lg mb-5 text-slate-600">
            Second to none! All of Reef Al Bustan Technical services are some
            times compiled into annual maintenance contracts, giving attention
            to our customers’ needs. Below are our services and offers you the
            finest in home maintenance service.
          </p>
          <p className="text-lg mb-5 text-slate-600">
            We will respond to any maintenance requests and planned preventive
            maintenance relating to:
          </p>
          <ul className="mb-[40px]">
            <li className="mb-[6px]">
              <p className="flex items-start gap-4">
                <span className="text-primary inline-block mt-1">
                  <FaCheck />
                </span>
                <span className="text-lg font-medium  text-slate-600">
                  Electrical supply, equipment and electrical maintenance
                </span>
              </p>
            </li>
            <li className="mb-[6px]">
              <p className="flex items-start gap-4">
                <span className="text-primary inline-block mt-1">
                  <FaCheck />
                </span>
                <span className="text-lg font-medium  text-slate-600">
                  Water supply, sanitary ware and drainage services and
                  maintenance.
                </span>
              </p>
            </li>
            <li className="mb-[6px]">
              <p className="flex items-start gap-4">
                <span className="text-primary inline-block mt-1">
                  <FaCheck />
                </span>
                <span className="text-lg font-medium  text-slate-600">
                  Air conditioning supply and associated equipment services and
                  maintenance.
                </span>
              </p>
            </li>
            <li className="mb-[6px]">
              <p className="flex items-start gap-4">
                <span className="text-primary inline-block mt-1">
                  <FaCheck />
                </span>
                <span className="text-lg font-medium  text-slate-600">
                  Waterproofing supply, installation & repairing
                </span>
              </p>
            </li>
            <li className="mb-[6px]">
              <p className="flex items-start gap-4  ">
                <span className="text-primary inline-block mt-1">
                  <FaCheck />
                </span>
                <span className="text-lg font-medium  text-slate-600">
                  Tiles, Painting, Carpentry
                </span>
              </p>
            </li>
          </ul>
          <Button varient={"link"} href="/book-service">
            Book a Service
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HeroFeatureSection;
