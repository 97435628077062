import React from "react";
import { FaCheck } from "react-icons/fa";

const ListGroup = ({ features }) => {
  return (
    <ul>
      {features?.map((item, idx) => (
        <li key={idx} className="mb-[6px]">
          <p className="flex items-start gap-4">
            <span className="text-primary inline-block mt-1">
              <FaCheck />
            </span>
            <span className="text-lg font-medium  text-slate-600">{item}</span>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default ListGroup;
