import React from "react";

// components
import Button from "../../components/Button/Button";

//images
import topBannerImg from "../../assets/images/main-2.jpg";

const TopBannerSection = () => {
  return (
    <section className="">
      <div
        style={{
          backgroundImage: `url(${topBannerImg})`,
        }}
        className="relative lg:py-[250px] py-[100px] h-[100vh] lg:mb-[100px] md:mb-[100px] mb-5 bg-cover bg-center bg-no-repeat z-0 overlay-primary"
      >
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center px-[10px]">
          <div className="text-white text-center">
            <p className="text-2xl font-medium mb-[15px]">
              The Best Service Provider Ever!
            </p>
            <p className="lg:text-[58px] md:text-[58px] text-4xl font-semibold leading-[1.3] mb-[20px]">
              Your Trusted Home Service Hub With <br /> Reef Al Bustan Technical
              Services CO. L.L.C
            </p>
            <p className="text-lg lg:w-[50%] md:w-[50%] w-[80%] mx-auto mb-[40px]">
              Welcome to "Reef Al Bustan" &#x2010; To be the preferred General
              Maintenance Company of our customers choice .One service provider
              for all technical works for our customer.
            </p>
            <Button varient="link" href="/book-service">
              Take On Service
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBannerSection;
