import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const InputDatePicker = ({ startDate, setStartDate }) => {
  return (
    <DatePicker
      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

export default InputDatePicker;
