import React from "react";
import TopBannerSection from "./TopBannerSection";
import ClientSection from "./ClientSection";
import TestimonialSection from "./TestimonialSection";
import ExpertsSection from "./ExpertsSection";
import WhyChooseUsSection from "./WhyChooseSection";
import WorkingProcessSection from "./WorkingProcessSection";
import ServicesSection from "./ServicesSection";
import HeroFeatureSection from "./HeroFeatureSection";
import BannerSection from "./BannerSection";
import FormAddReview from "../../components/Forms/FormAddReview";
import SliderShowcases from "../../components/Sliders/SliderShowcases";
import CountUpSection from "./CountUpSection";

const Home = () => {
  return (
    <div>
      <TopBannerSection />
      <HeroFeatureSection />
      <ServicesSection />
      <BannerSection />
      <WhyChooseUsSection />
      <WorkingProcessSection />
      <TestimonialSection />
      <CountUpSection />
      <section className="max-w-[1200px] mx-auto mt-[80px]">
        <SliderShowcases />
      </section>
      <ExpertsSection />
      <ClientSection />
      <section className="max-w-[1200px] mx-auto my-[50px]">
        <FormAddReview />
      </section>
    </div>
  );
};

export default Home;
