import { createBrowserRouter } from "react-router-dom";

// layout
import Main from "../layout/Main";

// pages
import Home from "../pages/Home/Home";
import Services from "../pages/Services/Services";
import Projects from "../pages/Projects/Projects";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from "../pages/ContactUs/ContactUs";
import FaqPage from "../pages/FaqPage/FaqPage";
import BookService from "../pages/BookService/BookService";
import ServiceDescription from "../pages/ServiceDescription/ServiceDescription";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "services/:id",
        element: <ServiceDescription />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "book-service",
        element: <BookService />,
      },
      {
        path: "faq",
        element: <FaqPage />,
      },
    ],
  },
]);

export default routes;
