import React, { useState } from "react";
import useModal from "../../hooks/useModal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

// components
import CardShowcaseItem from "../Cards/CardShowcaseItem";
import ModalShowShowcase from "../Modals/ModalShowShowcase";
import SliderLoader from "../Loader/SliderLoader";
import CardSliderError from "../Cards/CardSliderError";
import { useGlobalState } from "../../state/GlobalStateProvider";

const SliderShowcases = () => {
  const {
    showcases: { data },
  } = useGlobalState();
  let slidePerView = 3;
  if (window.screen.availWidth < 570) {
    slidePerView = 1;
  }

  const { modalIsOpen, openModal, closeModal } = useModal();
  const [modalViewImg, setModalViewImg] = useState(null);
  return (
    <>
      <div className="relative ">
        <h2
          className="rtin-title font-bold text-center"
          style={{ marginBottom: "0" }}
        >
          Our Projects
        </h2>
        <Swiper
          slidesPerView={slidePerView}
          spaceBetween={20}
          loop={true}
          pagination={false}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
          className="mySwiper showcases-slider"
        >
          {data?.map((project) => (
            <SwiperSlide
              key={project._id}
              // onClick={() => {
              //   setModalViewImg(`${apiUrl + "uploads/images/" + showcase.img}`);
              //   openModal();
              // }}
              // key={showcase?._id}
              className="cursor-pointer"
            >
              <CardShowcaseItem project={project} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ModalShowShowcase
          isOpen={modalIsOpen}
          closeModal={closeModal}
          img={modalViewImg}
        />
      </div>
    </>
  );
};

export default SliderShowcases;
