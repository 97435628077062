import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../state/GlobalStateProvider";

const CardShowcaseItem = ({ project }) => {
  const { apiUrl } = useGlobalState();
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className="relative overflow-hidden"
    >
      <img
        className="w-auto lg:md:h-[360px] h-[340px] object-cover"
        src={apiUrl + "public/uploads/images/" + project.image}
        alt=""
      />
      {/* <div
        className={`absolute top-0 left-0 w-full h-full bg-[#14287be6] flex items-center justify-center transition-all ease-out duration-500 ${
          hover ? " visible opacity-100 " : "invisible opacity-0"
        }`}
      >
        <div>
          <h4 className="text-white text-xl font-semibold">Project Title</h4>
        </div>
      </div> */}
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-end justify-start z-0 transition-all ease-linear duration-300`}
      >
        <div
          className={`absolute bottom-0 left-0 w-full h-full transition-all ease-linear duration-300 ${
            hover ? "opacity-100 translate-y-0" : "translate-y-[100%]"
          }`}
          style={{ backgroundColor: "#000000b5" }}
        ></div>
        <div
          className={`pb-[50px] pl-[30px] text-start transition-all ease-linear duration-300 ${
            hover ? "translate-y-[-30px]" : "translate-y-0"
          }`}
        >
          <div className="relative">
            <span className="absolute top-0 left-0 inline-block h-full w-[5px] bg-secondary"></span>
            <h4 className="text-white text-xl font-semibold ml-5">
              {project.title}
            </h4>
          </div>
          <div
            className={`transition-all ease-linear duration-300 ${
              hover
                ? "translate-y-0 visible h-auto"
                : "translate-y-[100%] invisible h-0"
            }`}
          >
            <h4 className="ml-5 mt-2 text-white text-sm">
              {project.description}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardShowcaseItem;
